/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';
import './auth';

import Vue from 'vue'
import PortalVue from 'portal-vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../sass/app.scss'

Vue.use(PortalVue)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

const autoComponents = require.context('./components/_auto/', true, /\.vue$/i)
autoComponents.keys().map(key => Vue.component('v'+key.split('/').pop().replace(/\.\w+$/, ''), autoComponents(key).default))

const customComponents = require.context('./components/_custom', true, /\.vue$/i)
customComponents.keys().map(key => Vue.component('custom'+key.split('/').pop().replace(/\.\w+$/, ''), customComponents(key).default))

const authComponents = require.context('./components/auth', true, /\.vue$/i)
authComponents.keys().map(key => Vue.component('auth'+key.split('/').pop().replace(/\.\w+$/, ''), authComponents(key).default))

const soComponents = require.context('./components/service/', true, /\.vue$/i)
soComponents.keys().map(key => Vue.component('service'+key.split('/').pop().replace(/\.\w+$/, ''), soComponents(key).default))


const app = new Vue({
    el: '#app',
});
