<template>
    <div>
        <custom-simple-resource route="/api/v1/service/sub-locality" :fields="fields" />
    </div>
</template>
<script>
export default {
    data(){
        return {
            fields: [
                {
                    key: 'origin_id',
                    label: 'Código',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'Nome',
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                    sortable: false,
                }
            ]
        }
    },
}
</script>