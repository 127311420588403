<template>
    <div class="container p-0 mb-2"> 
        <b-modal id="login" title="Identificação de Usuário"
            @ok="request_login"
            @cancel="reset_forms">
            <b-form ref="form-login" method="POST" action="#" @submit.prevent>
                <input type="hidden" name="_token" :value="token_csrf">
                <b-form-group
                    id="input-group-email"
                    label="E-mail*:"
                    label-for="input-email"
                    :description="null">
                    <b-form-input 
                        id="input-email" 
                        type="email"
                        name="email"
                        :state="form.state"
                        v-model="form.email"
                        required></b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-group-password"
                    label="Senha*:"
                    label-for="input-password"
                    :description="null">
                    <b-form-input 
                        id="input-password" 
                        type="password"
                        name="password"
                        :state="form.state"
                        v-model="form.password"
                        required></b-form-input>
                </b-form-group>
                <b-form-valid-feedback :state="form.state">
                    <b-spinner small label="Carregando..." class="fs-6 mr-2"></b-spinner>Usuário Identificado! Processando Login...
                </b-form-valid-feedback>
                <b-form-invalid-feedback :state="form.state">
                    {{ form.message }}
                </b-form-invalid-feedback>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
    export default {
        props: ['token_csrf'],
        data() {
            return {
                form: {
                    state: null,
                    email: null,
                    password: null,
                    message: null
                },
            }
        },
        methods: {
            request_login(e) {
                e.preventDefault()
                axios.post('api/auth/login', this.form)
                .then(response => {
                    if(response.data.data.access_token) {
                        this.form.state = true
                        document.cookie = 'access_token=' + response.data.data.access_token
                        setTimeout(() => {
                            this.$refs['form-login'].submit()
                        }, 1500)
                    }
                }).catch(this.catch)                
            },
            reset_forms() {
                this.form = {
                    email: null,
                    password: null
                }
            },
            catch(errors) {
                console.log(errors)
                this.form.state = false
                this.form.message = errors.error
                if(errors.response.data.error) {
                    this.form.message = errors.response.data.error
                }
            }
        },
        mounted() {
            this.$nextTick(() => {this.$bvModal.show('login')})
        }
    }
</script>
