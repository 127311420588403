<template>
    <div class="container p-0">
        <b-table small striped head-variant="dark" class="mb-0"
            :items="getData" :fields="fields"
            :select-mode="selectMode"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :caption="`${ getMessage } | Selecionado: ${ selected.length }`"
            responsive="sm"
            ref="selectableTable"
            selectable
            @row-selected="onRowSelected">
            <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                    <span aria-hidden="true">&check;</span>
                    <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                </template>
            </template>
            <template #row-details="row">
                <b-card>
                    <h6>
                        <b-icon-code variant="info" @click="show_pre=(show_pre==row.item.id? null: row.item.id)"/>
                        <span class="ms-1">INFORMAÇÕES</span>
                    </h6>
                    <pre v-show="show_pre==row.item.id">{{ row.item }}</pre>
                    <p>{{ row.item.description }}</p>
                </b-card>
            </template>
            <template #cell(actions)="row">
                <b-icon-info-circle @click="row.toggleDetails" title="Informações" variant="info"/>
            </template>
            <template #head(actions)="row">
                <b-dropdown v-if="selected.length>0" text="" :title="`${selected.length} items selecionados`">
                    <b-dropdown-item v-b-modal="`${id}-table-options-program`">Programar</b-dropdown-item>
                    <b-dropdown-item v-if="selected.length==1" disabled>Executar Manual</b-dropdown-item>
                </b-dropdown>
                <b-dropdown v-else disabled text="" title="Nenhum item selecionado..."></b-dropdown>
            </template>
        </b-table>
        <b-form inline class="d-flex justify-content-around">
            <b-form-select size="sm" class="m-0" v-model="selectMode" :options="selectModes" id="tb-mode-sel" />
            <b-form-select size="sm" class="m-0" v-model="perPage" :options="perPageModes" id="tb-perpage-sel" />
            <b-pagination size="sm" class="m-0" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" />
            <b-input-group size="sm" class="m-0">
                <b-form-input v-model="filter" type="search" id="filter-input" placeholder="Buscar por..." />
                <b-input-group-append>
                    <b-button v-b-modal="`${id}-table-filter-settings`">Settings</b-button>
                    <b-button :disabled="!filter" @click="filter=''">Clear</b-button>
                </b-input-group-append>
            </b-input-group>
            <!--b-button size="sm" class="m-0" @click="selectAllRows">Select all</b-button>
            <b-button size="sm" class="m-0" @click="clearSelected">Clear selected</b-button-->
        </b-form>
        <!-- Painel Configurações de Busca -->
        <b-modal :id="`${id}-table-filter-settings`"
            title="Configurações da Busca" hide-footer>
            <b-form-checkbox-group v-model="filterOn" class="m-1">
                <b-form-checkbox value="osc_id">Número da OSC</b-form-checkbox>
                <b-form-checkbox value="unidade_consumidora">Imóvel</b-form-checkbox>
                <b-form-checkbox value="servico">Serviço</b-form-checkbox>
            </b-form-checkbox-group>
            <b-button @click="filterOn=[]">Clear</b-button>
        </b-modal>
        <!-- Painel Programação de Execução de Serviços -->
        <b-modal 
            :id="`${id}-table-options-program`" :ref="`${id}-table-options-program`"
            :title="`Programar Execução de ${ selected.length } Serviços`"
            no-close-on-backdrop hide-footer>
            <b-table small :items="selected" :fields="fieldsCompact" responsive="sm" />
            <b-form inline class="d-flex justify-content-around">
                <b-button v-b-modal="`${id}-table-options-program-team`"
                    class="mx-auto" variant="primary">Confirmar Programação</b-button>
            </b-form>
            <b-modal :id="`${id}-table-options-program-team`" :ref="`${id}-table-options-program-team`"
                @show="onOpenTeamsModal" hide-footer
                title="Selecione o Executor" no-close-on-backdrop no-close-on-esc>
                <b-alert v-if="teamItems==null">
                    <b-spinner type="border" small/>Atualizando equipes...</b-alert>
                <b-table v-else small responsive="sm" :items="teamItems" :fields="teamFields"
                    select-mode="single" selectable @row-selected="onTeamRowSelected" />
                <b-form inline class="d-flex justify-content-around">
                    <b-button variant="danger"
                        @click="cancelProgramation">Cancelar</b-button>
                    <b-button variant="primary" v-if="selected!=null&&selectedTeam!=null"
                        @click="createProgramation(selected, selectedTeam)">Confirmar</b-button>
                </b-form>
            </b-modal>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: ['id', 'data'],
    data() {
        return {
            show_pre: null,
            selected: [],
            selectedTeam: null,
            teamItems: [],
            contentData: null,
            filter: null,
            filterOn: [],
            perPage: 10,
            currentPage: 1,
            selectMode: 'range',
            perPageModes: [
                {
                    value: 10,
                    text: '10 Linhas'
                },
                {
                    value: 25,
                    text: '25 Linhas'
                },
                {
                    value: 50,
                    text: '50 Linhas'
                },
            ],
            selectModes: [
                {
                    value: 'single',
                    text: 'Seleção Única'
                },
                {
                    value: 'multi',
                    text: 'Seleção Múltipla'
                },
                {
                    value: 'range',
                    text: 'Seleção Avançada'
                }
            ],
            teamFields: [
                {
                    key: 'descricao',
                    label: 'Equipes',
                    sortable: false,
                },
            ],
            fieldsCompact: [
                {
                    key: 'origin_id',
                    label: 'OSC',
                    sortable: false,
                },
                {
                    key: 'address',
                    label: 'Endereço',
                    sortable: false,
                },
            ],
            fields: [
                {
                    key: 'selected',
                    label: '#',
                    sortable: false,
                },
                {
                    key: 'origin_id',
                    label: 'OSC',
                    sortable: true,
                },
                {
                    key: 'address',
                    label: 'Endereço',
                    sortable: true,
                },
                {
                    key: 'locality.name',
                    label: 'Localidade',
                    sortable: true,
                },
                {
                    key: 'expiration_date',
                    sortable: true,
                    formatter: (value, key, item) => {
                        return this.date_to_humans(value);
                    },
                },
                {
                    key: 'service.name',
                    label: 'Serviço Solicitado',
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                    sortable: false,
                },
            ]
        }
    },
    computed: {
        rows() {
            return this.getData != null ? this.getData.length : 0
        },
        getMessage() {
            return (this.getResponseData.message ?? null);
        },
        getData() {
            return (this.getResponseData.data ?? null);
        },
        getResponseData() {
            let response = this.contentData ?? this.data;
            return (response != null) ? response.data : { message: 'loading...', data: null };
        },
    },
    methods: {
        date_to_humans(date) {
            return (new Date(String(date).substr(0, 19))).toLocaleDateString();
        },
        onRowSelected(items) {
            this.selected = items
        },
        onTeamRowSelected(items) {
            this.selectedTeam = items.length>0? items[0]: null
        },
        onOpenTeamsModal(event) {
            this.selectedTeam = null;
            this.teamItems = null;
            axios.get("/api/v1/equipe")
                .then((response) => {
                    this.teamItems = response.data.data;
                }).catch((error) => {}).finally(() => {});
        },
        createProgramation(services, team) {
            const data = {
                team_id: team.id,
                services: services.map(service => service.id)
            };
            axios.post("/api/v1/service/program", data)
                .then((response) => {
                    console.log(response.data);
                    this.$emit('changed-data');
                    this.cancelProgramation();
                    this.selected = [];
                }).catch((error) => {}).finally(() => {});
        },
        cancelProgramation() {
            this.selectedTeam = null;
            let modalProgamRef = `${this.id}-table-options-program`;
            let modalProgamTeamRef = `${this.id}-table-options-program-team`;
            this.$refs[modalProgamTeamRef].hide();
            this.$refs[modalProgamRef].hide();
        },
        selectAllRows() {
            this.$refs.selectableTable.selectAllRows()
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected()
        },
    }
}
</script>