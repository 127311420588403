<template>
    <div class="container">
        <b-card no-body>
            <b-tabs :id="id" small card v-model="tabIndex" @activate-tab="changeTabItem">
                <b-tab v-for="(tab, i) in tabItems" :key="i" no-body>
                    <template #title>
                        <b-spinner type="border" small v-show="tab.loading"/>
                        <span class="ml-1">{{ tab.title }}</span>
                    </template>
                    <component v-if="id && tab.id" @changed-data="requestTabData(tabIndex)"
                        :is="`${id}-${tab.id}`"
                        :id="`${id}-${tab.id}`"
                        :data="tab.data"/>
                    <div v-else class="alert alert-info" role="alert">
                        O componente '{{ `${id}-${tab.id}` }}' da aba '{{ tab.title }}' não foi definido.
                    </div>
                </b-tab>
            </b-tabs>
        </b-card>
        <div v-if="tabItems!=null" class="text-muted my-2">Aba {{ tabItems[tabIndex].title }}</div>
    </div>
</template>
<script>
export default {
    props: ['id', 'route', 'tabItems'],
    data(){
        return {
            tabIndex: 1,
        }
    },
    methods: {
        changeTabItem(newTabIndex, prevTabIndex, bvEvent) {
            this.tabItems[prevTabIndex].data = null;
            this.requestTabData(newTabIndex);
        },
        requestTabData(tabIndex) {
            this.tabItems[tabIndex].loading = true;
            this.tabItems[tabIndex].data = null;
            axios.get(`/api/v1/${this.route}`, {params: {type: this.tabItems[tabIndex].id}})
                .then((response) => {
                    this.tabItems[tabIndex].data = response;
                })
                .catch((error) => {
                    this.tabItems[tabIndex].data = error;
                }).finally(() => {
                    this.tabItems[tabIndex].loading = false;
                });
        }
    },
    mounted() {
        this.requestTabData(this.tabIndex)
    }
}
</script>