<template>
    <custom-nav-tabs id="service" route="service/order" title="Ordens de Serviço" :tab-items="tabItems"/>
</template>
<script>
export default {
    data(){
        return {
            tabIndex: 0,
            tabItems: [
                {
                    id: "received",
                    title: 'Recebidas',
                    loading: false,
                    data: null,
                },
                {
                    id: "executed",
                    title: 'Executadas',
                    loading: false,
                    data: null,
                },
                {
                    id: "preFinalized",
                    title: 'Pré-Baixada',
                    loading: false,
                    data: null,
                },
                {
                    id: "finalized",
                    title: 'Finalizadas',
                    loading: false,
                    data: null,
                }
            ]
        }
    },
}
</script>