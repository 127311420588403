<template>
    <div class="container p-0">
        <b-table small striped responsive="sm" head-variant="dark"
            :items="items" :fields="fields"
            :caption="getMessage">
            <template #head(actions)="row">
                <b-spinner v-if="status=='loading'" small disabled></b-spinner>
                <b-icon-arrow-down-up v-else @click="getIndexList"></b-icon-arrow-down-up>
            </template>
        </b-table>
    </div>
</template>
<script>
export default {
    props: ['route', 'fields'],
    computed: {
        getMessage() {
            return this.status=='loading'? 'Carregando...': this.message
        }
    },
    data(){
        return {
            status: null,
            message: null,
            items: [],
        }
    },
    methods: {
        getIndexList() {
            this.status = 'loading';
            this.items = []
            axios.get(this.route)
                .then((response) => {
                    this.status = 'success'
                    this.message = response.data.message;
                    this.items = response.data.data;
                }).catch((error) => {
                    this.status = 'error'
                    this.message = error;
                }).finally(() => {

                });
        }
    },
    mounted() {
        this.getIndexList();
    }
}
</script>