/** Interceptação de Requisições */
window.axios.interceptors.request.use(
    request => {
        if (request.method.equals == 'post') request.headers['Content-Type'] = 'multipart/form-data';
        if (!(request.url.endsWith('/api/auth/login'))) {
            let access_token = document.cookie.split(';').find(cookie => {return cookie.trim().startsWith('access_token=')});
            let jwt_access_token = access_token == null ? null : access_token.trim().split('=')[1];
            request.headers.Authorization = 'Bearer ' + jwt_access_token;
        }
        request.headers.Accept = 'application/json'
        return request
    },
    error => {
        return error
    }
);

/** Interceptação de Respostas */
window.axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        let status = error.response.status;
        let message = error.response.data.message;
        let url = error.response.request.responseURL;
        if (status == 401) {
            if (message.startsWith('Unauthenticated')) {
                if (url.endsWith('api/auth/refresh')) {
                    window.axios.post('/logout', {_token: token.content}).then(response => {window.location.reload();});
                } else {
                    window.axios.get('/api/auth/refresh').then(response => {
                        document.cookie = 'access_token=' + response.data.data.access_token;
                        jwt_access_token = response.data.access_token;
                        window.location.reload();
                    })
                }
            }
        } else if (status == 500) {
            if (message.startsWith('Token Signature could not be verified.') || message.startsWith('Token has expired and can no longer be refreshed')) {
                if ((!url.endsWith('logout'))) {
                    window.axios.post('/logout', {_token: token.content}).then(response => {window.location.reload();});
                }
            }
        }
        return error
    }
);